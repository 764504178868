import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { VariableSizeList as List } from 'react-window';
import { useDebounce } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import { api, Input } from '@webfx/core-web';
import { useAuth, useApp } from '@webfx/web-hooks';
import { useResize } from '@webfx/web-context';
import DropdownItem from '../DropdownItem';

import styles from './Menu.module.css';

const RowItem = React.forwardRef(({ data, index, style }, ref) => {
  return (
    <Dropdown.Item
      ref={ref}
      eventKey={data[index]}
      key={data[index].siteId}
      index={index}
      as={DropdownItem}
      data={data[index]}
      style={style}
    >
      {data[index].name ?? data[index].url}
    </Dropdown.Item>
  );
});

const Menu = React.forwardRef(({ onSearch }, ref) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const isAdmin = useAuth().isAdmin;
  const [app] = useApp({ fullObject: true });
  const activeProduct = app?.parentId || app?.productId;
  const { setRef, size: getSize } = useResize();
  const listRef = useRef();
  const listOuterRef = useRef();

  useEffect(() => {
    setRef(listRef);
  }, []);
  const sitesQuery = api.useInfiniteQuery([
    'sites',
    {
      $select: ['siteId', 'name', 'url'],
      $join: { status: true },
      $limit: 100,
      $sort: { name: 1, url: 1 },
      ...(activeProduct !== 'nutshell' && { product: activeProduct }),
      active: true,
      ...(!debouncedSearch ||
      (isNaN(Number(debouncedSearch)) && debouncedSearch.indexOf('app.') === -1)
        ? {
            url: { $ne: '', $notLike: 'app.nutshell.com/%' },
          }
        : {}),
      ...(debouncedSearch
        ? {
            $or: [
              { url: { $like: `%${debouncedSearch}%` } },
              { name: { $like: `%${debouncedSearch}%` } },
            ],
          }
        : {}),
    },
  ]);

  return (
    <div ref={ref} className={styles.menuWrapper}>
      <div className={styles.searchWrapper}>
        <Input
          className="mb-0"
          variant="sm"
          icon="search"
          iconPosition="left"
          placeholder={isAdmin ? 'Search for a Client' : 'Search for a Profile'}
          data-fx-name="searchClientProfile"
          onChange={(e) => {
            setSearch(e.target.value);
            if (onSearch) {
              onSearch(e);
            }
          }}
          value={search}
          clearable={true}
          onClear={() => {
            setSearch('');
          }}
          autoFocus
        />
      </div>
      {sitesQuery.isLoading ? (
        <div className="text-center">
          <Spinner animation="border" data-fx-name="searchClientProfileSpinner" />
        </div>
      ) : null}
      <List
        className="select-menu-items"
        outerRef={listOuterRef}
        innerElementType={({ children }) => (
          <InfiniteScroll
            scrollableTarget="select-list-wrapper"
            next={sitesQuery.fetchNextPage}
            hasMore={sitesQuery.hasNextPage}
            loader={<Spinner />}
            dataLength={sitesQuery.data?.total ?? 0}
          >
            {children}
          </InfiniteScroll>
        )}
        ref={listRef}
        height={200}
        itemSize={getSize}
        itemCount={sitesQuery?.data?.data?.length ?? 0}
        width="100%"
        itemData={sitesQuery?.data?.data ?? []}
        estimatedItemSize={60}
      >
        {RowItem}
      </List>
    </div>
  );
});

export default Menu;
